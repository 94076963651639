// Import webcimes-tooltip
import "@/node_modules/webcimes-tooltip/dist/css/webcimes-tooltip.css";
import { WebcimesTooltip } from "webcimes-tooltip";

// Export WebcimesTooltip
export { WebcimesTooltip };

// Import webcimes-modal
import "@/node_modules/webcimes-modal/dist/css/webcimes-modal.css";
import { WebcimesModal } from "webcimes-modal";

// Export WebcimesModal
export { WebcimesModal };

// Import webcimes-select
import "@/node_modules/webcimes-select/dist/css/webcimes-select.css";
import { WebcimesSelect } from "webcimes-select";

// Export WebcimesSelect
export { WebcimesSelect };

// css
import "@css/general.css";

// Import Axios
import axios from "axios";

// Étendre l'interface Window pour inclure axios
declare global {
  interface Window {
    axios: typeof axios;
  }
}

// Ajouter axios à l'objet global window
window.axios = axios;

// Définir l'en-tête par défaut pour toutes les requêtes HTTP
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Export axios
export { axios };

// Import gettext.ts
import { _t } from "@ts/gettext.ts";

// Export _t
export { _t };

// Wait for dom content loaded
document.addEventListener("DOMContentLoaded", function()
{
	// Remove preload-no-transition class
	document.body.classList.remove("preload-no-transition");
	
	// Apply old scroll position in case of refresh or redirect to the current page
    let scrollTop = sessionStorage.getItem("scrollTop");
	if(scrollTop)
	{
		sessionStorage.removeItem("scrollTop");
		scrollTop = (scrollTop.split("|")[0] === document.location.pathname ? scrollTop.split("|")[1] : null);
		if(scrollTop) {window.scrollTo(0, parseInt(scrollTop))};
	}

	// Set tooltip title
	document.querySelectorAll("[title]").forEach((el: HTMLElement) => {
		const tooltipTitle = new WebcimesTooltip({
			type: "title",
			element: el,
		});
	});
});

// Save scroll position in case of refresh or redirect to the current page
window.addEventListener("beforeunload", function () {
	sessionStorage.setItem("scrollTop", document.location.pathname+"|"+window.scrollY.toString());
});